import * as React from "react";
import { navigate, type HeadFC } from "gatsby";
import PartContent from "../../../components/parts/PartContent";
import { useEffect, useState } from "react";
import { useLocation } from "@reach/router";

const PartPage = ({ params }: { params: { part_id: string } }) => {


  const location = useLocation();
  const [return_to_build_id, set_return_to_build_id] = useState<string>("");
  const [return_to_build_name, set_return_to_build_name] = useState<string>("");
  useEffect(() => {
    set_return_to_build_id(typeof window !== 'undefined' && location.search.includes("return_to_build") ? location.search.split("return_to_build=")[1].split('&')[0] : "");
    set_return_to_build_name(typeof window !== 'undefined' && location.search.includes("return_to_build_name") ? location.search.split("return_to_build_name=")[1] : "");
  }, [location]);

  const part_type = "psu";
  let part_id: string = "";
  if (!!params && params[`part_id`]) {
    part_id = params[`part_id`];
  }

  useEffect(() => {
    if (!!params && !part_id) {
      navigate("/404/");
    }
  }, [part_id]);

  return <PartContent part_id={part_id} part_type={part_type} return_to_build={return_to_build_id} return_to_build_name={return_to_build_name} />;
};

export default PartPage;

export const Head: HeadFC = () => <title>Power Supply - BitLot.app</title>;
